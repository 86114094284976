.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-trello-board {
  background-color: #e3e3e3;
}

.container-stick {
  height: calc(100vh - 250px);
  overflow-y: auto;
}

.react-dropdown-select-type-single span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 85%;
}
.PhoneInputInput {
  height: 2.5rem;
  padding: 0.5rem 0.5rem;
  margin-top: 3px;
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.PhoneInputInput::placeholder {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.flexible-modal {
  z-index: 51 !important;
  position: absolute;
  border: 1px solid #e5e7eb !important;
  background: white;
}

.flexible-modal-mask {
  z-index: 50;
  position: fixed;
  height: 100%;
  background: rgba(55, 55, 55, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.flexible-modal-resizer {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: se-resize;
  margin: 5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}

.flexible-modal-drag-area {
  background: rgba(205, 211, 205, 0.24) !important;
  height: 60px !important;
  position: absolute;
  right: 0;
  top: 0;
  cursor: move;
}

.flexible-modal-drag-area-left {
  display: none;
}

.flexible-modal-drag-area-bottom {
  /* display: none; */
  height: 50px;
  background: rgba(205, 211, 205, 0.24) !important;
  cursor: move;
}

.flexible-modal-drag-area-right {
  display: none;
}
.react-tagsinput {
  margin-top: 0.25rem /* 4px */;
  margin-bottom: 0.25rem /* 4px */;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
  border-radius: 0.5rem /* 8px */;
}

.react-tagsinput--focused {
  border-color: #e2e8f0;
}

.react-tagsinput-tag {
  background-color: #cbd5e1;
  border-radius: 2px;
  border: 1px solid #94a3b8;
  color: #475569;
  display: inline-block;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: " ×";
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 80px;
}
